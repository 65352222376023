.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  background-color: transparent; /* Background color for the navbar */
}

/* Style for the links in the menu */
.navbar-nav .nav-link {
  color: black; /* Text color for the links */
}

/* Style for the hamburger menu icon */
.navbar-toggler-icon {
  background-color: white; /* Color of the hamburger icon lines */
}



.background {
  background-size: cover;
  background-position: center;
  height: 100%; /* Adjust height as needed */
  background-image: url('./images/cloudbackground.png');
  overflow-y: auto;
  position: relative; /* Ensure the .backcolor positions relative to this container */
  z-index: 1;
}
/* You can define additional styles for your content here */

.backcolor {
  position: absolute; /* Position it over the container-fluid */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #008AFC; /* Black background with 50% opacity */
  opacity: .2;
  z-index: -1;
}
.container-fluid {
  
  width: 100%; 
  max-width: 1200px; /* Adjust as necessary */
}

.nametag{
  color: white;
  font-size: 48px;
  font-weight: bold;
  padding-top: 20px;
  margin-bottom: -10px;
  width: 1260px;
}

.profile-content {
  
  display: flex;
  align-items: center; 
  margin-top: -20px;
  margin-left: 140px;
  margin-right: 140px;
}

.profile-picture {
  border-radius: 50%;
  border: 4px solid #AECFF3;
  width: 230px;
  height: 230px;
  margin-right: 20px;

}


.skills {
  color: white;
  font-weight: 600;
  font-size: 17px;
  padding: 0;
  margin-top: 20px;
  margin-left: 15px;
  text-align: left; 
  width: 600px; /* Fixed width for larger screens */
  
}



.contact-info {
  width: 1200px; /* Assuming this is the intended max-width of the container */
  text-align: center; /* Or 'left', depending on desired alignment */
  /* Add other styles as necessary */
}

.contact-info a {
  color: white;
  font-weight: 600;
  text-decoration: none;
  margin-top: 30px;
  margin-right: 70px; /* Adjusted for spacing, not too wide */

  display: inline-block;
}


.contact-info a .link-icon {
  width: 32px; /* Example size, adjust as needed */
  height: 32px; /* Example size, adjust as needed */
  margin-right: 10px; /* Spacing between the icon and the text */
  vertical-align: middle; /* Aligns icon with the text */
}

.contact-info a .linkedmail {
  width: 26px; /* Example size, adjust as needed */
  height: 26px; /* Example size, adjust as needed */
  margin-right: 10px; /* Spacing between the icon and the text */
  vertical-align: middle; /* Aligns icon with the text */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 columns */

  gap: 0px; /* Space between grid items */
  justify-items: center; /* Center items horizontally in their cell */
  align-items: center; /* Center items vertically in their cell */
  justify-content: center; /* Center the entire grid horizontally */
  align-content: center; /* Center the entire grid vertically */
 
  margin-left: 85px;
  width: 960px;
  max-width: 960px; /* Adjust the max-width as needed */
  padding: 20px;
}



.image-grid a {
  display: flex;
  flex-direction: column;
  align-items: center; 
  margin-top: 10px;
  text-decoration: none;
}

.scavholder {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the images horizontally */
  text-decoration: none;
}

.image-grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  width: 960px;
  gap: 16px; /* Space between grid items */
  justify-items: center; /* Center items horizontally in their cell */
  align-items: center; /* Center items vertically in their cell */
  justify-content: center; /* Center the entire grid horizontally */
  align-content: center; /* Center the entire grid vertically */

  margin-left: 85px;
  max-width: 960px; /* Adjust the max-width as needed */
  padding: 20px;
}

.downloads{
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates 3 columns */
  gap: 16px; /* Space between grid items */
  justify-items: center; /* Center items horizontally in their cell */
  align-items: center; /* Center items vertically in their cell */
  justify-content: center; /* Center the entire grid horizontally */
  align-content: center; /* Center the entire grid vertically */
}

.image-grid2 a {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the images horizontally */
  text-decoration: none;
}

.grid-itemtop {
  width: 199px; /* Make images take up the full grid cell width */
  height: 360px; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: add a border radius for styled corners */
  border: 4px solid #AECFF3;
  margin-bottom: 10px;
}

.grid-itembottom1 {
  width: 403px; /* Make images take up the full grid cell width */
  height: 184px; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: add a border radius for styled corners */
  margin-bottom: 10px;
  border: 4px solid #AECFF3;
}

.grid-itembottom2 {
  width: 414px; /* Make images take up the full grid cell width */
  height: 189px; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: add a border radius for styled corners */
  margin-bottom: 10px;
  border: 4px solid #AECFF3;
}


.git-icon {
  width: 32px; /* Example size, adjust as needed */
  height: 32px; /* Example size, adjust as needed */
  
}

.apple-icon{
  width: 27px; /* Example size, adjust as needed */
  height: 27px; /* Example size, adjust as needed */
  
}
.projects{
  display: none;
}

/*------------------Mobile CSS--------------------*/

@media (max-width: 768px) {

  
.background {
  overflow-x: hidden;
  background-size: cover;
  background-position: center;
  height: auto; /* Adjust height as needed */
  width: auto;
  background-image: url('./images/cloudbackground.png');
  overflow-y: auto;
  position: relative; /* Ensure the .backcolor positions relative to this container */
  z-index: 1;
}
/* You can define additional styles for your content here */

.backcolor {
  position: absolute; /* Position it over the container-fluid */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #008AFC; /* Black background with 50% opacity */
  opacity: .2;
  z-index: -1;
}
  .container-fluid {
   
    width: auto; 
    max-width: 768px; /* Adjust as necessary */
  }
  
  .nametag{
    color: white;
    font-size: 32px;
    font-weight: bold;
    padding-top: 20px;
    margin-bottom: -10px;
    width: auto;
    text-align: center; /* Center the text */
  }

  .profile-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates 3 columns */
    gap: 16px; /* Space between grid items */
    justify-items: center; /* Center items horizontally in their cell */
    align-items: center; /* Center items vertically in their cell */
    justify-content: center; /* Center the entire grid horizontally */
    align-content: center; 
  }

  .profile-picture {
    border-radius: 50%;
    margin-top: 35px;
    border: 4px solid #AECFF3;
    width: 240px;
    height: 240px;
  
  }

  .skills {
    color: white;
    font-weight: 600;
    font-size: 20px;
    padding: 0;
    text-align: left; 
    margin-top: -5px;
    width: 300px; /* Fixed width for larger screens */
  }
  
  .gitlink{
    margin-left: -3px;
  }

  .contact-info {
    margin-top: -5px;
    margin-bottom: 50px;
    text-align: left; 
    margin-left: 25px;
    width: 400px; 
  }
  
  .contact-info a {
    color: white;
    font-weight: 600;
    font-size: 20px;
    text-decoration: none;
    margin-top: 30px;
    display: inline-block;
  }
  
  
  .contact-info a .link-icon {
    width: 42px; /* Example size, adjust as needed */
    height: 42px; /* Example size, adjust as needed */
    margin-right: 10px; /* Spacing between the icon and the text */
    vertical-align: middle; /* Aligns icon with the text */
  }
  
  .contact-info a .linkedmail {
    width: 36px; /* Example size, adjust as needed */
    height: 36px; /* Example size, adjust as needed */
    margin-right: 10px; /* Spacing between the icon and the text */
    vertical-align: middle; /* Aligns icon with the text */
  }
  
  .image-grid {
   display: grid;
    grid-template-columns: repeat(1, 1fr); 
    gap: 16px; 
    justify-items: center; 
    align-items: center; 
    justify-content: center; 
    align-content: center; 
    width: 220px;
  }
  
  
  
  .image-grid a {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: 10px;
    text-decoration: none;
  }
  
  .scavholder {
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-decoration: none;
  }
  
  .image-grid2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    gap: 16px; 
    justify-items: center; 
    align-items: center; 
    justify-content: center; 
    align-content: center; 
    width: 220px;
  }
  
  .downloads{
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 16px; 
    justify-items: center; 
    align-items: center; 
    justify-content: center; 
    align-content: center; 
  }
  
  .image-grid2 a {
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center the images horizontally */
    text-decoration: none;
  }
  
  .grid-itemtop {
    width: 220px; /* Make images take up the full grid cell width */
    height: 380px; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: add a border radius for styled corners */
    border: 4px solid #AECFF3;
    margin-bottom: 10px;
  }
  
  .grid-itembottom1 {
    width: 372px; /* Make images take up the full grid cell width */
    height: 223px;
    border-radius: 8px; /* Optional: add a border radius for styled corners */
    margin-bottom: 10px;
    border: 4px solid #AECFF3;
  }
  
  .grid-itembottom2 {
    width: 372px; /* Make images take up the full grid cell width */
    height: 223px;
    border-radius: 8px; /* Optional: add a border radius for styled corners */
    margin-bottom: 10px;
    border: 4px solid #AECFF3;
  }
  
  
  .git-icon {
    width: 42px; /* Example size, adjust as needed */
    height: 42px; /* Example size, adjust as needed */
    
  }
  
  .apple-icon{
    width: 35px; /* Example size, adjust as needed */
    height: 35px; /* Example size, adjust as needed */
    
  }

  .projects{
    font-size: 32px;
    font-weight: 600;
   display: inline;
   color: white;
    text-align: center;
  }
  
}

